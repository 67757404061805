<template>
  <v-container class="box-content pa-0 pl-4 pr-4 pt-4" fluid>

    <v-col cols='12'>
      <DialogWarning
        :open='dialogAlert'
        :message='dialog.message'
        :screenTitle='dialog.screenTitle'
        v-on:confirm="dialogConfirmation"
        v-on:cancel="dialogDenial"
        :denyDialog='dialog.denyTextButton'
        :acceptDialog='dialog.acceptTextButton'/>
    </v-col>

      <FormStepController
        :steps="steps"
        :loading="loading"
        title="Cadastrar Assunto para Atendimento"
        v-on:formSubbmit="formSubmit"
        @formDelete="formDelete"
        :form="form"
        :editing="editing">

        <GeneralInformationForm
          :form="form"
          @validateRequired="validateRequired"
          :editing="editing"
          :loading="loading"
          :validationRules="steps['GeneralInformationForm'].rules"/>

      </FormStepController>

  </v-container>
</template>

<script>

import FormStepController from '@/components/template/form/FormStepController';
import GeneralInformationForm from '@/views/topic/forms/GeneralInformationForm';
import utils from '@/utils/utils';
import notification from '@/service/notification';
import {
  get, post, put, remove,
} from '@/service/api';
import { routerPush } from '@/service/kplaceRouter';
import DialogWarning from '@/components/template/alert/DialogWarning';

export default {
  components: {
    FormStepController,
    GeneralInformationForm,
    DialogWarning,
  },
  data() {
    return {
      editing: false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      form: {
        title: '',
        enabled: true,
        description: '',
        orderInfoRequired: false,
        sellerAuthRequired: false,
        koerichAdminAuthRequired: false,
      },
      dialogAlert: false,
      loading: true,
      dialog: {},
      savedForm: null,
      formModified: false,
      steps: {
        GeneralInformationForm: {
          step: 1,
          status: {},
          required: {
            title: true,
            description: false,
          },
          rules: {
            title: (value) => {
              if (!value || !value.length > 0) {
                return 'Este campo é obrigatório.';
              }
              if (value && value.length > 65) {
                return 'Texto muito extenso';
              }
              return true;
            },
            description: (value) => {
              if (value && value.length > 255) {
                return 'Texto muito extenso';
              }
              return true;
            },
          },
        },
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.editing = true;
    }
    utils.setStepStatus(this);
    utils.setStepRequireds(this);
  },

  async mounted() {
    try {
      this.loading = true;
      if (this.editing) {
        await this.getTopic(this.$route.params.id);
      }
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async getTopic(id) {
      try {
        const { data } = await get(`help-assistance-management/topics/${id}`);
        this.form = {
          ...data,
        };
      } catch (e) {
        notification('Erro ao recuperar o assunto');
      }
    },
    validateRequired(tab, field, value) {
      this.steps[tab].required[field] = !(this.steps[tab].rules[field](value) === true);
      utils.revalidate(this);
    },
    postTopic(body) {
      post('help-assistance-management/topics/', body, {
        headers: {},
      })
        .then((response) => {
          if (response.status === 201) {
            notification('O cadastro do assunto foi salvo com sucesso.', 'success');
            routerPush('/topic');
          } else {
            notification(`Mensagem: ${response.response.data.message}`, 'error');
          }
        })
        .catch((error) => {
          notification('Erro ao criar novo assunto', 'error');
        });
    },
    putTopic(body) {
      put(`help-assistance-management/topics/${this.$route.params.id}`, body, {
        headers: {},
      })
        .then((response) => {
          if (response.status === 200) {
            notification('O assunto foi atualizado com sucesso.', 'success');
            routerPush('/topic');
          } else {
            notification('Verifique os dados e tente novamente', 'error');
          }
        })
        .catch((error) => {
          notification(`Erro ao atualizar tópico: ${error.response.data.message}`, 'error');
        });
    },
    formSubmit() {
      const body = {
        ...this.form,
      };
      if (!this.editing) {
        this.postTopic(body);
      } else {
        this.putTopic(body);
      }
    },
    formDelete() {
      this.setDialogTexts(
        'Todas as informações deste assunto serão perdidas com esta ação.',
        'Deseja mesmo excluir o assunto para atendimento?',
        'SIM, QUERO EXCLUIR',
        'CANCELAR',
      );
      this.dialogAlert = true;
    },
    deleteTopic() {
      this.dialogAlert = false;
      remove(`help-assistance-management/topics/${this.$route.params.id}`, {}, {
        headers: {},
      })
        .then((response) => {
          if (response.status === 204) {
            notification('O assunto foi removido com sucesso.', 'success');
            routerPush('/topic');
          } else {
            const error = response.response.data;
            notification(error.message, 'error');
          }
        }).catch((error) => {
          notification(`Erro ao atualizar tópico: ${error}`, 'error');
        });
    },
    dialogConfirmation() {
      this.deleteTopic();
    },
    dialogDenial() {
      this.saveDialogAlert = false;
      this.dialogAlert = false;
    },
    setDialogTexts(screenTitle, message, acceptTextButton, denyTextButton) {
      this.dialog.screenTitle = screenTitle;
      this.dialog.message = message;
      this.dialog.acceptTextButton = acceptTextButton;
      this.dialog.denyTextButton = denyTextButton;
    },
  },
};

</script>

<style>

.box-content {
  background-color: rgb(237, 237, 237);
  height: calc(100% - 80px);
  margin-top: 80px
}

</style>
