<template>
  <v-row class='row-space'>
    <div class='font-label' :style='labelStyle'>
      {{ label }}
    </div>
    <v-switch
      class='ml-8 mt-0'
      :label="boolValue ? 'Sim' : 'Não'"
      v-model='boolValue'
      :disabled="disabled"
      :readonly="readOnly"
      validate-on-blur filled dense light flat />
  </v-row>
</template>

<script>

import Vue from 'vue';

export default Vue.extend({

  props: {
    label: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    labelHAlign: { type: String, default: 'left' },
    labelVAlign: { type: String, default: 'middle' },
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
  },
  computed: {
    labelStyle() {
      return 'width: '
        .concat(this.labelWidth)
        .concat('; text-align: ')
        .concat(this.labelHAlign)
        .concat('; vertical-align: ')
        .concat(this.labelVAlign);
    },
    boolValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
});

</script>

<style>
</style>
