<template>
  <v-skeleton-loader
    :loading="loading"
    type="article, actions"
  >
    <FormContent :selected='selected' class='pa-6'>
      <v-row class='margin-fix pa-3'>
        <v-col xl="6" lg="6" sm="12" md="6">
          <TextFieldInput label='Assunto*'
                          placeHolder='Informe o assunto do chamado'
                          label-width="20%"
                          :maxLength="65"
                          @focusout="validate('title')"
                          v-model="form.title"
                          :validationRules='[validationRules.title]'/>
        </v-col>
        <v-col xl="6" lg="6" sm="12" md="6">
          <SwitchInput label='Ativo'
                       label-width="20%"
                       v-model="form.enabled"
                       style="margin-left: 50px"/>
        </v-col>
      </v-row>
      <v-row class='margin-fix pa-3'>
        <v-col xl="12" lg="12" sm="12" md="5">
          <TextAreaInput label='Descrição'
                         placeHolder='Informe uma descrição breve do assunto, se necessário'
                         v-model="form.description"
                         @focusout="validate('description')"
                         :validationRules='[validationRules.description]'
                         label-width="10%"
                         :maxLength="255"/>

        </v-col>
      </v-row>
      <v-row class="subtext subtext_form_devide_h3">
        <h5>Configurações para abertura do atendimento</h5>
      </v-row>
      <v-row class='margin-fix pa-3'>
        <v-col xl="6" lg="6" sm="12" md="6">
          <SwitchInput label='Requer número do pedido?'
                       placeHolder='Sim'
                       label-width="30%"
                       v-model="form.orderInfoRequired"/>
        </v-col>
      </v-row>
      <v-row class="subtext subtext_form_devide_h3" style="margin-top: 40px">
        <h5>Configurações para finalização do atendimento</h5>
      </v-row>
      <v-row class='margin-fix pa-3'>
        <v-col xl="6" lg="6" sm="12" md="6">
          <SwitchInput label-width="30%"
                       label='Requer autorização do seller?'
                       v-model="form.sellerAuthRequired"/>
        </v-col>
        <v-col xl="6" lg="6" sm="12" md="6">
          <SwitchInput label-width="30%"
                       label='Requer autorização do administrador?'
                       v-model="form.koerichAdminAuthRequired"/>
        </v-col>
      </v-row>
      <v-row class="subtext subtext_form_devide_h3" style="margin-top: 40px">
        <h5>Configuração para cancelamento do pedido</h5>
      </v-row>
      <v-row class='margin-fix pa-3'>
        <v-col xl="6" lg="6" sm="12" md="6">
          <SwitchInput label-width="30%"
                       label='Requer autorização do seller e do administrador?'
                       v-model="form.cancelOrderEnabled"/>
        </v-col>
      </v-row>
    </FormContent>
  </v-skeleton-loader>
</template>

<script>

import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import SwitchInput from '@/components/template/form/input/SwitchInput';
import TextAreaInput from '@/components/template/form/input/TextAreaInput';

export default {
  name: 'GeneralInformationForm',
  data: () => ({
    selected: false,
  }),
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Informações gerais',
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    validate(field) {
      this.$emit('validateRequired', 'GeneralInformationForm', field, this.form[field]);
    },
  },
  components: {
    FormContent,
    TextFieldInput,
    SwitchInput,
    TextAreaInput,
  },
};
</script>

<style>
</style>
