<template>
  <div>
    <v-row class='row-space'>
      <div class='font-label' :style='labelStyle'>
        {{ label }}
      </div>
      <v-textarea
        :placeholder='placeHolder'
        v-model='textValue'
        :error="error"
        @focusout="focusout"
        :error-messages="error_message"
        :disabled="disabled"
        :readonly="readOnly"
        validate-on-blur
        filled
        dense
        light
      />

    </v-row>
    <div v-if="maxLength" class="count v-messages theme--light v-messages__message message-transition-enter-to">
      {{ textValue ? textValue.length : 0 }}/ {{ maxLength }}
    </div>
  </div>
</template>

<script>

import Vue from 'vue';

export default Vue.extend({
  name: 'TextAreaInput',
  props: {
    label: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    validationRules: { type: Array },
    labelHAlign: { type: String, default: 'left' },
    maxLength: { type: Number },
    labelVAlign: { type: String, default: 'middle' },
    placeHolder: { type: String, default: '' },
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      error_message: null,
      error: false,
    };
  },
  methods: {
    focusout() {
      this.$emit('focusout');
    },
    validate(value) {
      if (this.validationRules) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.validationRules.length; i++) {
          const validate = this.validationRules[i];
          const result = validate(value);
          this.error = result.hasError;
          this.error_message = result.hasError ? result.message : null;
        }
        this.$forceUpdate();
      }
    },
  },
  computed: {
    labelStyle() {
      return 'width: '
        .concat(this.labelWidth)
        .concat('; text-align: ')
        .concat(this.labelHAlign)
        .concat('; vertical-align: ')
        .concat(this.labelVAlign);
    },
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.validate(value);
        this.$emit('input', value);
      },
    },
  },
});
</script>

<style>
.count {
  float: right
}
</style>
