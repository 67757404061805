































































import Vue from 'vue';

export default Vue.extend({
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    message: {
      type: String,
      required: false,
      default: 'Deseja cancelar o cadastro deste produto?',
    },
    screenTitle: {
      type: String,
      required: false,
      default: 'cadastro de produto',
    },
    denyDialog: {
      type: String,
      required: false,
      default: 'Não',
    },
    acceptDialog: {
      type: String,
      required: false,
      default: 'Sim',
    },
  },
  data(): { dialog: false } {
    return {
      dialog: false,
    };
  },
  computed: {},
  methods: {
    close(): void {
      this.dialog = false;
      this.$emit('cancel');
    },
    exit(): void {
      this.dialog = false;
      this.$emit('confirm');
    },
  },
  watch: {
    open(newVal): void {
      if (this.dialog !== newVal) {
        this.dialog = newVal;
      }
    },
  },
});
